import { Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { ClaimStatusColor, IClaimStatus } from "../../constants/claimsStatus"
import { IClaim } from "../../types/graphqlResponse"
import { formatAmount, getFormattedDate } from "../../utils/date"
import insertIntoArray from "../../utils/insertIntoArray"
import DownloadPopover from "../DownloadPopover"
import { InfoCircleOutlined } from "@ant-design/icons"
import { isTouchableScreen } from "../../utils/general"
import globals from "../../constants/globals"
import BonusCalculations from "./BonusCalculations"
import CustomTooltip from "../CustomTooltip"
import IconAndFirstname from "../../features/ClaimNotes/IconAndFirstname"

const { IS_ADMIN } = globals

const firstName: ColumnsType<IClaim> = [
  {
    title: "First Name",
    fixed: "left",
    render(_, record) {
      return <IconAndFirstname record={record} />
    },
  },
]

const lastName: ColumnsType<IClaim> = [
  {
    title: "Last Name",
    dataIndex: "lastName",
  },
]

const productID: ColumnsType<IClaim> = [
  {
    title: "Parts Number",
    dataIndex: "productIdManual",
  },
]

const model: ColumnsType<IClaim> = [
  {
    title: "Model",
    dataIndex: "combinedField",
  },
]

const orderID: ColumnsType<IClaim> = [
  {
    title: "Order / Receipt ID",
    dataIndex: "orderId",
  },
]

const quantity: ColumnsType<IClaim> = [
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
]

const awardAmount: ColumnsType<IClaim> = [
  {
    title: "Award Amount",
    dataIndex: "awardValue",
    render(value) {
      return value ? formatAmount(value) : "$0.00"
    },
  },
]

// This column varies for admin and consumer and
// also on whether the screen has touch points
const bonusAmount: ColumnsType<IClaim> = [
  {
    title: IS_ADMIN ? (
      <CustomTooltip
        title={`${
          isTouchableScreen ? "Tap on" : "Hover over"
        } amount to see calculation details`}
      >
        Bonus Amount <InfoCircleOutlined />
      </CustomTooltip>
    ) : (
      "Bonus Amount"
    ),
    dataIndex: "bonusValue",
    render(_, { bonusCriteria, bonusValue }) {
      const value = formatAmount(bonusValue)
      return IS_ADMIN ? (
        <BonusCalculations bonusCriteria={bonusCriteria} bonusValue={value} />
      ) : (
        value
      )
    },
  },
]

const dateOfSale: ColumnsType<IClaim> = [
  {
    title: "Sale Date",
    dataIndex: "dateOfSale",
    render: (value) => getFormattedDate(value),
  },
]

const claimDate: ColumnsType<IClaim> = [
  {
    title: "Claim Date",
    dataIndex: "createdAt",
    render: (value) => getFormattedDate(value),
  },
]

const declineReasonTextColumn: ColumnsType<IClaim> = [
  {
    title: "Decline Reason",
    dataIndex: "declinedReasons",
    width: "300px",
  },
]

const approvedDeclinedDateColumn: ColumnsType<IClaim> = [
  {
    title: "Approved / Declined Date",
    dataIndex: "statusUpdatedAt",
    render: (value) => getFormattedDate(value),
  },
]

const approvedDeclinedByColumn: ColumnsType<IClaim> = [
  {
    title: "Approved / Declined By",
    dataIndex: "approvedOrDeclinedByName",
  },
]

const dealerNameColumn: ColumnsType<IClaim> = [
  {
    title: "Dealer Name",
    dataIndex: "dealerName",
  },
]

const uploadedReceiptsColumns: ColumnsType<IClaim> = [
  {
    title: "Uploaded Reciept",
    dataIndex: "claimDocumentUrl",
    render: (_, { claimDocumentUrl }) =>
      claimDocumentUrl.length > 0 && (
        <DownloadPopover urls={claimDocumentUrl} title="Download Receipts" />
      ),
  },
]

const status: ColumnsType<IClaim> = [
  {
    title: "Status",
    dataIndex: "status",
    render: (val: IClaimStatus, { isProcessed }) => {
      return (
        <Tag color={isProcessed ? "success" : ClaimStatusColor[val]}>
          {isProcessed ? "FUNDED" : val.toUpperCase()}
        </Tag>
      )
    },
  },
]

const productDescription: ColumnsType<IClaim> = [
  {
    title: "Model",
    width: "300px",
    dataIndex: "productDescription",
    key: "3",
    render: (val) => {
      return (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {val}
        </div>
      )
    },
  },
]

const depositDate: ColumnsType<IClaim> = [
  {
    title: "Deposit Date",
    dataIndex: "orderFundedAt",
    render: (value) => getFormattedDate(value),
  },
]

const claimId: ColumnsType<IClaim> = [
  {
    title: "Claim ID",
    dataIndex: "internalClaimID",
  },
]

const trackingNumber: ColumnsType<IClaim> = [
  {
    title: "Tracking Number",
    dataIndex: "trackingNumber",
  },
]

/** These columns are being used in Claim Table
 * and Edit Claim Table
 */
const normalClaimColumns: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...status,
  ...claimId,
  ...trackingNumber,
  ...productID,
  ...model,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...bonusAmount,
  ...dateOfSale,
  ...claimDate,
  ...depositDate,
  ...approvedDeclinedDateColumn,
  ...approvedDeclinedByColumn,
  ...declineReasonTextColumn,
  ...dealerNameColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

/** Consumer Side Claim Table */
const myAccountClaimColumns: ColumnsType<IClaim> = [
  ...status,
  ...productID,
  ...claimId,
  ...productDescription,
  ...model,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...bonusAmount,
  ...dateOfSale,
  ...claimDate,
  ...approvedDeclinedDateColumn,
  ...declineReasonTextColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

const claimsApprovalColumns: ColumnsType<IClaim> = [
  ...firstName,
  ...lastName,
  ...claimId,
  ...productID,
  ...model,
  ...orderID,
  ...quantity,
  ...awardAmount,
  ...bonusAmount,
  ...dateOfSale,
  ...claimDate,
  ...depositDate,
]

const pendingClaimsColumns: ColumnsType<IClaim> = [
  ...claimsApprovalColumns,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))
const approvedClaimsColumns: ColumnsType<IClaim> = [
  ...insertIntoArray(3, claimsApprovalColumns, trackingNumber),
  ...approvedDeclinedDateColumn,
  ...approvedDeclinedByColumn,
  ...dealerNameColumn,
  ...uploadedReceiptsColumns,
].map((column, key) => ({ ...column, key }))

const declinedClaimsColumns: ColumnsType<IClaim> = [
  ...insertIntoArray(3, approvedClaimsColumns, declineReasonTextColumn),
]
  .filter((col) => col.dataIndex !== "trackingNumber")
  .map((column, key) => ({ ...column, key }))

export {
  pendingClaimsColumns,
  approvedClaimsColumns,
  declinedClaimsColumns,
  normalClaimColumns,
  myAccountClaimColumns,
}
